import { useMutation } from '@tanstack/react-query'

import { api } from '../../http'

type OrderValidationProps = {
  orderId?: string | null
  isAutomaticValidation?: boolean | null
}

const sendOrderValidation = async ({ orderId, isAutomaticValidation }: OrderValidationProps) => {
  const response = await api.post(
    `${
      import.meta.env.VITE_ORDER_VALIDATION_API_URL
    }/${orderId}?isAutomaticValidation=${isAutomaticValidation}`,
    {},
    {
      headers: {
        'x-api-key': import.meta.env.VITE_ORDER_VALIDATION_API_KEY,
      },
    },
  )
  return response.data
}

export const useOrderValidationMutation = (OrderValidationProps?: OrderValidationProps) => {
  const orderId = OrderValidationProps?.orderId

  return useMutation({
    mutationKey: ['validation', orderId],
    mutationFn: sendOrderValidation,
  })
}
