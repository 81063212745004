import { WarningFilled } from '@ant-design/icons'
import { colors } from '@design-system/styles/theme'

import { GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'

import { ErrorStyled } from '../style'

interface PortoFormQuotationProps {
  orderData: GetOrderInformationBody
  validateOrderDispatch: boolean | undefined
}

export const PortoViewForm = ({ orderData, validateOrderDispatch }: PortoFormQuotationProps) => {
  return (
    <>
      <li>Número Cotação Operadora*</li>
      <p>
        {orderData?.quotation?.externalId ? (
          orderData?.quotation?.externalId
        ) : validateOrderDispatch &&
          (orderData?.status === 'FILLED' || orderData?.status === 'PENDING_DISPATCH') ? (
          <ErrorStyled>
            <WarningFilled style={{ color: colors.red }} />
            Dado obrigatório
          </ErrorStyled>
        ) : (
          '-'
        )}
      </p>
    </>
  )
}
